@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
}

html, body {
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0 8px;
  background-image: url("/src/images/back_1.jpg");
}

#root {
  height: 100%;
  width: 100%;;
}

.MuiFilledInput-root {
  background-color: white !important;
}

.MuiInputLabel-filled {
  color: #854B23 !important;
}

.MuiFilledInput-underline:after {
  border-color: #854B23 !important;
}

.image-gallery {
  width: 90%;
}
